import { ISchema } from '@formily/react'
import { BackgroundStyleSetter, VwSizeInput } from '../setters/components'

export const CSSStyle: ISchema = {
  type: 'void',
  properties: {
    'style.width': {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': VwSizeInput,
    },
    'style.height': {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'SizeInput',
    },
    'style.overflowY': {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-decorator-props': {
        tooltip: '只有设置高度才会生效'
      },
      'x-component': 'Select',
      "x-component-props": {
        allowClear: true,
        placeholder: '谨慎选择'
      },
      enum: [{
        label: '滚动',
        value: 'auto',
      }, {
        label: '隐藏',
        value: 'hidden',
      }],
    },
    'style.display': {
      'x-component': 'DisplayStyleSetter',
    },
    'style.textAlign': {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: ['left', 'center', 'right', 'justify', 'inherit'],
      "x-component-props": {
        defaultValue: 'inherit'
      }
    },
    'style.background': {
      'x-component': BackgroundStyleSetter,
    },
    'style.boxShadow': {
      'x-component': 'BoxShadowStyleSetter',
    },
    'style.font': {
      'x-component': 'FontStyleSetter',
    },
    'style.margin': {
      'x-component': 'BoxStyleSetter',
    },
    'style.padding': {
      'x-component': 'BoxStyleSetter',
    },
    'style.borderRadius': {
      'x-component': 'BorderRadiusStyleSetter',
    },
    'style.border': {
      'x-component': 'BorderStyleSetter',
    },
    'style.opacity': {
      'x-decorator': 'FormItem',
      'x-component': 'Slider',
      'x-component-props': {
        defaultValue: 1,
        min: 0,
        max: 1,
        step: 0.01,
      },
    },
  },
}
